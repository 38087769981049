<template>
  <div class="page-container">
      <div class="options-row">

      </div>
    <Table :columns="columns" :data="data" :loading="loading"></Table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      columns: [],
      data: [],
      loading: false,
      form:{
          
      }
    };
  },
  methods: {},
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>